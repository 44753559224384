<template>
	<div>
		<departmentList dictionaryCode="POST"></departmentList>
	</div>
</template>

<script>
export default {
	name: 'postList',
	components: {
		departmentList: () => import('../departmentList/index.vue'),
	},
};
</script>

<style lang="scss" scoped>
</style>